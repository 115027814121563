import React from "react";
import Typewriter from "typewriter-effect";
import Header from "../header/header";

export default function LandingPage() {
  return (
    <div style={{ width: '100%', height: '100%', position: 'absolute', textAlign: 'start' }}>
      <div style={{ backgroundColor: 'rgb(62,62,63)' }}>
      <div style={{ padding: 16 }}>
        <Header />
      </div>
      </div>
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
        <p style={{ margin: 0, color: 'white', fontFamily: 'Roboto', fontSize: 30 }}>
          <Typewriter
            options={{
              strings: ["Brand new mobile developers community", "Coming soon..."],
              autoStart: true,
              loop: true,
            }}
          />
        </p>
      </div>
    </div>
  );
}
