import "./App.css";
import LandingPage from "./components";


function App() {
  return (
     <div className="App" style={{width: '100%', height: '100%', flexDirection: 'column', alignContent: "center", alignItems: 'center', justifyContent: 'center'}}>
      <LandingPage />
     </div>
  );
}

export default App;
